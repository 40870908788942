html {
  font-size: 15px;
}

body {
  font-family: 'Xilosa';
}

.leaflet-container {
  height: 100%;
  width: 100%;
}
